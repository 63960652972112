import logo from './TSLogo.PNG';
import './App.css';
import * as React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { MarketingFooter } from './ui-components'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We're working on getting these machines set up!          
        <br/>
           Check back soon!
        </p>
      </header>
      <footer>
        <MarketingFooter width="100%"></MarketingFooter>
      </footer>
    </div>
  );
}

export default App;
